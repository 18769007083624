import React from "react";
import _ from "lodash";
import List from "../List";
import styles from "./index.module.scss";
import { mediaTypes } from "../../utils/media";
import { toppingToShortString } from "../../utils/menuItemOrderData";
import PlateIcon from "../icons/Plate.svg";

const OrderItem = ({
  item,
  currencySymbol,
  children,
  T,
  bottomBorder = true,
  light,
  noBorder = false,
  hideImage,
  appStyles,
  multipliers = [],
  noImageRenderer: NoImageRenderer = PlateIcon,
  posInnerMenuId,
}) => {
  const { rtl, orderItemImageFit } = appStyles;

  return (
    <List.Item bottomBorder={bottomBorder} noBorder={noBorder} tight>
      {!hideImage && (
        <List.Item.Image
          width={90}
          height={90}
          imagePreview={item.imagePreview}
          mediaKey={item.imageKey}
          mediaType={
            item.useCategoryImage
              ? mediaTypes.menuCategory
              : mediaTypes.menuItem
          }
          sizes={"90px"}
          imgStyle={{ objectFit: orderItemImageFit || "cover" }}
          style={{
            ...(rtl ? { marginLeft: 10 } : { marginRight: 10 }),
            borderRadius: appStyles.cardBorderRadius,
          }}
          classNames={styles.ItemImage}
          noImageRenderer={NoImageRenderer}
        />
      )}

      <List.Item.Content>
        <List.Item.Title light>
          <strong>{T(item.title)}</strong>
          {item.price != null && !isNaN(item.price) && (
            <strong style={{ flexShrink: 0, direction: "ltr" }}>
              {currencySymbol}
              {item.price.toFixed(2)}
              {item.count > 1 && ` x ${item.count}`}
            </strong>
          )}
        </List.Item.Title>
        {item.size && (
          <span>
            <strong style={light && lightFontStyle}>{`${T("Size")}: `}</strong>
            <span>{T(item.size)}</span>
          </span>
        )}
        {!item.size && item.servingSize && (
          <span>
            <strong style={light && lightFontStyle}>{`${T("Size")}: `}</strong>
            <span>{T(item.servingSize.name)}</span>
          </span>
        )}
        {_.map(
          _.filter(
            item.orderItemComponents,
            (component) =>
              component.selectedOptions && component.selectedOptions.length,
          ),
          (component, index) => (
            <span key={index}>
              <strong style={light && lightFontStyle}>{`${T(
                component.title,
              )}: `}</strong>
              <span>
                {component.selectedOptions
                  .map((option) => {
                    const optionPrice = getBranchSpecificPriceForOption(
                      option,
                      posInnerMenuId,
                    );
                    return optionPrice
                      ? `${T(option.title)} (${currencySymbol}${optionPrice})`
                      : T(option.title);
                  })
                  .join(", ")}
              </span>
            </span>
          ),
        )}
        {_.map(
          _.filter(
            item.toppingsCategories,
            (toppingCategory) => toppingCategory.toppings.length,
          ) || [],
          (toppingCategory, index) => (
            <span key={index}>
              <strong
                style={light && lightFontStyle}
              >{`${gettoppingCategoryTitle(toppingCategory, T)}: `}</strong>
              <span>
                {_.map(toppingCategory.toppings, (topping) =>
                  toppingToShortString(topping, multipliers, T),
                ).join(", ")}
              </span>
            </span>
          ),
        )}
        {Array.isArray(item.ingredients) &&
          _.filter(item.ingredients, ["isSelected", false]).length > 0 && (
            <span>
              <strong style={light && lightFontStyle}>{`${T(
                "Ingredients:",
              )} `}</strong>
              {_.map(
                _.filter(item.ingredients, ["isSelected", false]),
                (ingredient) => `${T("No")} ${ingredient.name}`,
              ).join(", ")}
            </span>
          )}
        {item.remark && (
          <span>
            <strong style={light && lightFontStyle}>{T("Comments")}:</strong>{" "}
            {item.remark}
          </span>
        )}
        {children}
      </List.Item.Content>
    </List.Item>
  );
};

const gettoppingCategoryTitle = (toppingCategory, T) => {
  const { maxNumOfMarkedOptionsIncluded } = toppingCategory;

  if (maxNumOfMarkedOptionsIncluded > 0) {
    return `${T(toppingCategory.title)} (${maxNumOfMarkedOptionsIncluded} ${T(
      "included",
    )})`;
  }
  return T(toppingCategory.title);
};

const getBranchSpecificPriceForOption = (option, posInnerMenuId) => {
  const chosenOptionPosInnerMenuItem = _.find(
    _.get(option, "posInnerMenuSpecificPrices"),
    { posInnerMenuId },
  );
  const optionPrice = chosenOptionPosInnerMenuItem
    ? _.get(chosenOptionPosInnerMenuItem, "price")
    : option.price;

  return optionPrice;
};

const lightFontStyle = { fontWeight: "normal" };

export default OrderItem;

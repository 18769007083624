import React from 'react';
import AppContainer from '../AppContainer';
import Button from '../Button';
import EmptyBagIcon from '../icons/EmptyStateBag.svg';
import PizzaCarryoutIcon from '../icons/PizzaCarryout.svg';
import styles from './index.module.scss';

export default ({ T, appStyles, description, shouldNavigateToServingOptions, onClick, disableAction }) => (
  <AppContainer.Content tightBottom appStyles={appStyles}>
    <AppContainer.CenteredColumn style={{ marginTop: '100px' }}>
      {appStyles.isPizza ? (
        <PizzaCarryoutIcon style={{ color: appStyles.accentColor }} className={styles.EmptyBagIcon} />
      ) : (
        <EmptyBagIcon style={{ color: appStyles.accentColor }} className={styles.EmptyBagIcon} />
      )}
      <h2 style={{ color: appStyles.accentColor }}>{description}</h2>
      {!disableAction && (
        <Button
          appStyles={appStyles}
          onClick={onClick}
          to={shouldNavigateToServingOptions ? '/find-location' : '/serving-options'}
        >
          {T('Start New Order')}
        </Button>
      )}
    </AppContainer.CenteredColumn>
  </AppContainer.Content>
);
